import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
/* eslint-disable import/no-cycle */
import { format } from 'date-fns';
import { Table } from 'react-bootstrap';
import type {
  ContractShopUpdateFormResponse,
  IdNameOutputResponse,
  IncResultOutputResponse,
  MatterHandOverSalesInfoOutputResponse,
  MonitorRsvOutputResponse,
  PostingRequestRsvDetailInfoOutputResponse,
  PostingRsvSecondCheckRequestFormResponse,
  RsvContractShopOutputResponse,
} from '../../../api-client';
import {
  ContractApi,
  ContractShopUpdateApi,
  PostingRequestDetailInfoApi,
  PostingRequestRsvDetailInfoApi,
  PostingRequestStatusChangeApi,
  PostingRsvDownloadApi,
  PostingRsvSecondCheckRequestApi,
  PostingRsvUploadApi,
} from '../../../api-client';
import { Overwrite } from '../../../interfaces/utils';
import { createTestId, execDownload, when } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { Url } from '../../../constants/Url';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { Alert } from '../../atoms/Alert';
import { ShopListModal } from '../../organisms/Modal/ShopListModal';
import { BasicInfoModal } from '../../organisms/Modal/BasicInfoModal';
import { StatusModal } from '../../organisms/Modal/StatusModal';
import { PostingReservationShopTable } from '../../organisms/Table/PostingReservationShopTable';
import { Dropzone } from '../../molecules/Dropzone';
import { Modal } from '../../molecules/Modal';
import { PostingSummaryTable2, Summary2 } from '../../organisms/Table/PostingSummaryTable2';
/* eslint-enable import/no-cycle */

export type Summary = Partial<
  Omit<
    PostingRequestRsvDetailInfoOutputResponse,
    'applicationFromId' | 'contractShops' | 'postingRequest' | 'matterHandoverSalesList'
  >
>;

export type MonitorRsvs = MonitorRsvOutputResponse & { checked: boolean };

export type ContractShop = Overwrite<RsvContractShopOutputResponse, { monitorRsvs: MonitorRsvs[] }>;

export interface Index {
  shop?: number;
  monitor?: number;
}

export interface State {
  getApi: PostingRequestRsvDetailInfoApi;
  getDetailApi: PostingRequestDetailInfoApi;
  statusChangeApi: PostingRequestStatusChangeApi;
  secondCheckApi: PostingRsvSecondCheckRequestApi;
  updateApi: ContractShopUpdateApi;
  contractApi: ContractApi;
  dlApi: PostingRsvDownloadApi;
  ulApi: PostingRsvUploadApi;
  postingRequestId: number;
  summary: Summary2;
  contractShops: ContractShop[];
  isBasicInfoModal: boolean;
  isStatusModal: boolean;
  isShopListModal: boolean;
  monitorBaseId: number;
  monitorBaseIds: number[];
  index: Index;
  updResult: IncResultOutputResponse;
  matterHandoverSalesList: MatterHandOverSalesInfoOutputResponse[];
  postingRequestStatus: number;
  applicationFormStatus: number;
  statusRsvAlertMonitorBases: IdNameOutputResponse[];
  postingRsvAlertMonitorBases: IdNameOutputResponse[];
  uploadFile?: File;
}

interface Monitor {
  contractShopName: string;
  monitorName: string;
  applicationStartDate: string;
}

export const APPLICATION_FORM_TYPE = {
  NEW: '新規',
  ADD: '追加',
  CHANGE: '変更',
  CANCEL: '解約',
  PAUSE: '休止',
};

export const PostingReservationPage: React.VFC = () => {
  const testid = createTestId(PostingReservationPage);

  const history = useHistory();
  const params = useParams<{ id: string }>();
  const initialState = {
    getApi: new PostingRequestRsvDetailInfoApi(),
    getDetailApi: new PostingRequestDetailInfoApi(),
    statusChangeApi: new PostingRequestStatusChangeApi(),
    secondCheckApi: new PostingRsvSecondCheckRequestApi(),
    updateApi: new ContractShopUpdateApi(),
    contractApi: new ContractApi(),
    dlApi: new PostingRsvDownloadApi(),
    ulApi: new PostingRsvUploadApi(),
    postingRequestId: Number(params.id),
    summary: {},
    contractShops: [],
    isBasicInfoModal: false,
    isStatusModal: false,
    isShopListModal: false,
    monitorBaseId: 0,
    monitorBaseIds: [],
    index: {},
    updResult: { result: false },
    matterHandoverSalesList: [],
    postingRequestStatus: 0,
    applicationFormStatus: 0,
    statusRsvAlertMonitorBases: [],
    postingRsvAlertMonitorBases: [],
    uploadFile: undefined,
  };

  const monitorsInitState = [
    {
      contractShopName: '',
      monitorName: '',
      applicationStartDate: '',
    },
  ];

  const { state: $, mergeState, useBindSet } = useLargeState<State>(initialState);
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [isDropModal, setIsDropModal] = useState<boolean>(false);
  const [isOkModal, setIsOkModal] = useState<boolean>(false);
  const [isCancelModal, setIsCancelModal] = useState<boolean>(false);
  const [isSubmitModal, setIsSubmitModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [isPostingOkModal, setIsPostingOkModal] = useState<boolean>(false);
  const [modalDisplayMonitors, setModalDisplayMonitors] = useState<Monitor[]>(monitorsInitState);
  const [confirmUploadResult, setConfirmUploadResult] = useState<{ result: boolean; message?: string } | undefined>(
    undefined
  );

  const [msg, setMeg] = useState<string | undefined>();
  const [isOpenRsvCompleteConfirm, setIsOpenRsvCompleteConfirm] = useState<boolean>(false);
  const [isOpenRsvCancelConfirm, setIsOpenRsvCancelConfirm] = useState<boolean>(false);

  const openRsvCompleteConfirmModal = () => setIsOpenRsvCompleteConfirm(true);
  const closeRsvCompleteConfirmModal = () => setIsOpenRsvCompleteConfirm(false);

  const openRsvCancelConfirmModal = () => setIsOpenRsvCancelConfirm(true);
  const closeRsvCancelConfirmModal = () => setIsOpenRsvCancelConfirm(false);

  // 0：依頼前
  // 1：依頼中→依頼完了
  // 2：依頼完了→予約完了
  // 3：クライアント確認OK
  // 4：クライアント確認NG
  // 5：依頼取消し
  // 6：二次チェック依頼中
  const POSTING_STATUS = {
    BEFORE_REQUEST: 0,
    REQUEST_COMPLETE: 1,
    RESERVE_COMPLETE: 2,
    CLIENT_OK: 3,
    CLIENT_NG: 4,
    REQUEST_CANCEL: 5,
    SECONDARY_CHECK: 6,
  };

  useEffect(() => {
    if (!$.postingRequestId) return;

    $.getApi
      .postingRequestRsvDetailInfo($.postingRequestId)
      .then((res: AxiosResponse<PostingRequestRsvDetailInfoOutputResponse>) => {
        const {
          applicationFormId,
          applicationFormName,
          applicationFormType,
          contractStartDate,
          contractEndDate,
          clientId,
          clientName,
          contractShops,
          matterHandoverSalesList,
          postingRequestStatus,
          statusRsvAlertMonitorBases,
          postingRsvAlertMonitorBases,
          trackerType,
          trackerTypeName,
        } = res.data;
        mergeState({
          contractShops: contractShops.map((s) => ({
            ...s,
            monitorRsvs: s.monitorRsvs.map((m) => ({ ...m, checked: false })),
          })),
          summary: {
            applicationFormId,
            applicationFormName,
            applicationFormType,
            contractStartDate,
            contractEndDate,
            clientId,
            clientName,
            trackerType,
            trackerTypeName,
          },
          matterHandoverSalesList,
          postingRequestStatus,
          statusRsvAlertMonitorBases,
          postingRsvAlertMonitorBases,
        });
      });
  }, [$.postingRequestId, $.getApi, mergeState]);

  const download = () => {
    $.contractApi.postingRequestFileDl($.postingRequestId).then((res) => {
      execDownload(res.data, 'text/csv', `posting_reqeust_${format(new Date(), 'yyyy_MM_dd_hh_mm_ss')}.xlsx`).catch(
        () => {
          history.push(Url.COMMON_ERROR);
        }
      );
    });
  };
  const shopDownload = () => {
    $.dlApi.postingRsvDownload($.postingRequestId).then((res) => {
      execDownload(
        res.data,
        'text/csv',
        `${$.summary.clientName}_${$.summary.applicationFormName}_${$.postingRequestId}_${format(
          new Date(),
          'yyyy_MM_dd_hh_mm_ss'
        )}.xlsx`
      ).catch(() => {
        history.push(Url.COMMON_ERROR);
      });
    });
  };
  const onDrop = (files: File[]) => {
    if (files === null || files.length === 0) return;
    const file = files[0];

    const reader = new FileReader();
    reader.onload = () => {
      $.ulApi
        .postingRsvUpload({
          data: reader.result?.toString().replace(/data:.*\/.*;base64,/, '') || '',
          dryRun: true,
        })
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          const { result, ...rest } = res.data;
          console.log({ checkResult: result, ...rest });
          if (res.data.result) {
            setConfirmUploadResult({ result: true, message: res.data.errorMessage });
            mergeState({ uploadFile: file });
          } else {
            setMeg(res.data.errorMessage);
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });
    };
    reader.readAsDataURL(file);
  };

  const onCancelUpload = () => {
    setIsDropModal(false);
    setConfirmUploadResult(undefined);
    mergeState({ uploadFile: undefined });
  };

  const onConfirmUpload = () => {
    if ($.uploadFile) {
      const reader = new FileReader();
      reader.onload = () => {
        $.ulApi
          .postingRsvUpload({
            data: reader.result?.toString().replace(/data:.*\/.*;base64,/, '') || '',
            dryRun: false,
          })
          .then((res: AxiosResponse<IncResultOutputResponse>) => {
            const { result, ...rest } = res.data;
            console.log({ checkResult: result, ...rest });

            if (res.data.result) {
              setIsOkModal(true);
              setIsDropModal(false);
            } else {
              setMeg(res.data.errorMessage);
            }
          })
          .catch(() => {
            history.push(Url.COMMON_ERROR);
          })
          .finally(() => {
            setConfirmUploadResult(undefined);
            mergeState({ uploadFile: undefined });
          });
      };
      reader.readAsDataURL($.uploadFile);
    }
  };

  const onSecondcheck = () => {
    const request: PostingRsvSecondCheckRequestFormResponse = {
      postingRequestId: $.postingRequestId,
      rsvs: $.contractShops.flatMap((shop) =>
        shop.monitorRsvs.map(({ monitorBaseId, monitorPostingRsvId }) => {
          return { monitorBaseId, monitorPostingRsvId };
        })
      ),
    };
    $.secondCheckApi
      .postingRsvSecondCheckRequest(request)
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ updResult: res.data });
        setIsSubmited(true);
        setIsSubmitModal(true);
        setModalMessage('二次チェック依頼を完了しました');
      })
      .catch((error: IncResultOutputResponse) => {
        mergeState({ updResult: error });
      });
  };

  const saveShopListModal = (id: number, shopName: string, url: string) => {
    if ($.index.shop !== undefined && $.index.shop !== null) {
      const form: ContractShopUpdateFormResponse = { id: $.contractShops[$.index.shop].id, shopId: id };
      $.updateApi.contractShopUpdate(form).then(() => {
        mergeState({
          contractShops: $.contractShops.map((s, i) => (i === $.index.shop ? { ...s, shopId: id, shopName, url } : s)),
          isShopListModal: false,
          index: {},
        });
      });
    }
  };

  const onHideShopListModal = () => mergeState({ index: {}, isShopListModal: false });

  const mergeShopParam = (shopId: number, shopName: string, url: string) => {
    mergeState({
      contractShops: $.contractShops.map((s, i) => {
        if (i === $.index.shop) {
          return { ...s, shopId, shopName, url };
        }
        return s;
      }),
    });
  };

  // 日付をyyyy/mm/dd形式にフォーマット
  const formatDate = (date: Date): string => {
    const y: number = date.getFullYear();
    const m: string = `00${date.getMonth() + 1}`.slice(-2);
    const d: string = `00${date.getDate()}`.slice(-2);
    return `${`${y}/${m}/${d}`}`;
  };

  // TODO どこかの map メソッドで key を設定しろと警告がでているようだ。

  // アラート対象のモニターを取得する
  const getAlertMonitors = () => {
    // モニターごとに表示開始日が先月以前になっていないか判定し、契約店舗名、モニター名、表示開始日のみを取り出す
    let monitors: { contractShopName: string; monitorName: string; applicationStartDate: string }[] = [];
    $.contractShops.forEach(({ contractShopName, monitorRsvs }) => {
      monitorRsvs.forEach(({ monitorName, applicationStartDate }) => {
        // 表示開始日
        const startDate = applicationStartDate ? Number(new Date(applicationStartDate)) : null;
        // 今月初日
        const thisMonthFirstDay = new Date(formatDate(new Date())).setDate(1);
        if (startDate && startDate - thisMonthFirstDay < 0) {
          monitors = [
            ...monitors,
            { contractShopName, monitorName, applicationStartDate: applicationStartDate || '-' },
          ];
        }
      });
    });
    return monitors;
  };

  const onStatusChange = (status: number) => {
    setIsPostingOkModal(false);

    $.statusChangeApi
      .postingRequestStatusChange({ id: $.postingRequestId, status })
      .then(() => {
        if (status === POSTING_STATUS.REQUEST_CANCEL) {
          setIsCancelModal(true);
        } else {
          setIsSubmitModal(true);
          if (status === POSTING_STATUS.REQUEST_COMPLETE) {
            setModalMessage('二次チェック依頼を差し戻しました');
          } else if (status === POSTING_STATUS.RESERVE_COMPLETE) {
            setModalMessage('掲載予約を完了しました');
          }
        }
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      });
  };

  const checkApplicationStartDate = (status: number) => {
    const alertMonitors = getAlertMonitors();
    if (alertMonitors.length) {
      setModalDisplayMonitors(alertMonitors);
      setIsPostingOkModal(true);
      return;
    }
    onStatusChange(status);
  };

  const handleRsvCompleteYes = () => {
    closeRsvCompleteConfirmModal();

    // 掲載完了本処理
    checkApplicationStartDate(POSTING_STATUS.RESERVE_COMPLETE);
  };

  const handleRsvCancelYes = () => {
    closeRsvCancelConfirmModal();

    // 掲載取り消し本処理
    onStatusChange(POSTING_STATUS.REQUEST_CANCEL);
  };

  return (
    <>
      <Modal
        centered
        isModal={isOkModal}
        body={
          <div className="text-center">
            <p>アップロードしました</p>
            <Button onClick={() => history.go(0)}>OK</Button>
          </div>
        }
      />
      {confirmUploadResult?.result && (
        <Modal
          centered
          isModal
          body={
            <div className="text-center">
              <p>{confirmUploadResult.message}</p>
              <div className="d-flex justify-content-center mb-2 gap-2">
                <Button onClick={onCancelUpload} variant="outline-secondary">
                  キャンセル
                </Button>
                <Button onClick={onConfirmUpload}>OK</Button>
              </div>
            </div>
          }
        />
      )}
      {msg && (
        <Modal
          centered
          isModal
          body={
            <div className="text-center">
              <p>エラー：{msg}</p>
              <Button onClick={() => setMeg(undefined)}>OK</Button>
            </div>
          }
        />
      )}
      <Modal
        centered
        isModal={isDropModal}
        closeButton
        onHide={() => setIsDropModal(false)}
        body={
          <>
            <Dropzone onDrop={onDrop} type="excel" />
          </>
        }
      />
      <Modal
        centered
        isModal={isCancelModal}
        body={
          <div className="text-center">
            <p>掲載依頼を取り消しました</p>
            <Button onClick={() => history.push(Url.KEISAI.POSTING_REQUEST_LIST)}>OK</Button>
          </div>
        }
      />
      <Modal
        centered
        isModal={isSubmitModal}
        body={
          <div className="text-center">
            <p>{modalMessage}</p>
            <Button onClick={() => history.go(0)}>OK</Button>
          </div>
        }
      />

      <Modal
        centered
        isModal={isOpenRsvCompleteConfirm}
        body={
          <div>
            <p>依頼を完了させると編集できなくなります。問題ないですか？</p>
            <div className="d-flex justify-content-center mb-2 gap-2">
              <Button onClick={handleRsvCompleteYes}>はい</Button>
              <Button onClick={closeRsvCompleteConfirmModal}>いいえ</Button>
            </div>
          </div>
        }
      />
      <Modal
        centered
        isModal={isOpenRsvCancelConfirm}
        body={
          <div>
            <p>取り消すと再度依頼から作成が必要になります。問題ないですか？</p>
            <div className="d-flex justify-content-center mb-2 gap-2">
              <Button onClick={handleRsvCancelYes}>はい</Button>
              <Button onClick={closeRsvCancelConfirmModal}>いいえ</Button>
            </div>
          </div>
        }
      />

      <Modal
        onHide={() => setIsPostingOkModal(false)}
        closeButton
        centered
        isModal={isPostingOkModal}
        body={
          <>
            <div className="text-center">
              <p>以下のモニターの表示開始日が先月以前になっています。</p>
            </div>
            <Table className="w-100 mb-4 border-0">
              <thead>
                <tr>
                  <th>契約店舗名</th>
                  <th>モニター名</th>
                  <th>表示開始日</th>
                </tr>
              </thead>
              <tbody className="py-4">
                {modalDisplayMonitors.map(({ contractShopName, monitorName, applicationStartDate }) => (
                  <tr>
                    <td className="border-0">{contractShopName}</td>
                    <td className="border-0">{monitorName}</td>
                    <td className="border-0">{applicationStartDate}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="text-center">
              <p>このまま掲載完了してよろしいですか？</p>
            </div>
            <div className="d-flex justify-content-center mb-2 gap-2">
              <Button onClick={() => onStatusChange(POSTING_STATUS.RESERVE_COMPLETE)}>はい</Button>
              <Button onClick={() => setIsPostingOkModal(false)}>いいえ</Button>
            </div>
          </>
        }
      />
      <Title className="mb-4">{TITLE.KEISAI.POSTING_RESERVATION}</Title>

      {$.updResult.result && (
        <Alert testId={testid('success-alert')} variant="success">
          実施しました。
        </Alert>
      )}
      {$.updResult.errorMessage && (
        <Alert
          testId={testid('failure-alert')}
          variant="danger"
        >{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</Alert>
      )}

      {$.statusRsvAlertMonitorBases && $.statusRsvAlertMonitorBases.length > 0 && (
        <Alert testId={testid('status-rsv-alert')} variant="danger">
          下記のモニターは別の掲載依頼でステータス予約が作成されています。
          {$.statusRsvAlertMonitorBases.map((d, index) => (
            <li>{d.name}</li>
          ))}
        </Alert>
      )}
      {$.postingRsvAlertMonitorBases && $.postingRsvAlertMonitorBases.length > 0 && (
        <Alert testId={testid('posting-rsv-alert')} variant="danger">
          下記のモニターは別の掲載依頼で掲載予約が作成されています。
          {$.postingRsvAlertMonitorBases.map((d, index) => (
            <li key={index}>{d.name}</li>
          ))}
        </Alert>
      )}

      {($.index.shop === 0 || $.index.shop) && (
        <ShopListModal
          isModal={$.isShopListModal}
          onSave={saveShopListModal}
          onHide={onHideShopListModal}
          contractShopId={$.contractShops[$.index.shop].id}
          mergeParam={mergeShopParam}
        />
      )}

      <BasicInfoModal
        isModal={$.isBasicInfoModal}
        setIsModal={useBindSet('isBasicInfoModal')}
        monitorBaseId={$.monitorBaseId}
        setMonitorBaseId={useBindSet('monitorBaseId')}
        modifyFlg
      />

      <StatusModal
        isModal={$.isStatusModal}
        setIsModal={useBindSet('isStatusModal')}
        postingRequestId={$.postingRequestId}
        monitorBaseIds={$.monitorBaseIds}
        setMonitorBaseIds={useBindSet('monitorBaseIds')}
      />

      <PostingSummaryTable2 info={$.summary} />

      <div className="d-flex justify-content-end mb-4 gap-2">
        <Button
          variant="link"
          onClick={() => history.goBack()}
          disabled={$.postingRequestStatus === POSTING_STATUS.REQUEST_CANCEL}
        >
          キャンセル
        </Button>

        {/* <Button */}
        {/*  variant="link" */}
        {/*  type="button" */}
        {/*  onClick={() => */}
        {/*    history.push({ */}
        {/*      pathname: Url.KEISAI.APPLICATION_MODIFY, */}
        {/*      state: { */}
        {/*        applicationId: $.summary.applicationFormId, */}
        {/*        clientId: $.summary.clientId, */}
        {/*        clientName: $.summary.clientName, */}
        {/*      }, */}
        {/*    }) */}
        {/*  } */}
        {/*  disabled={$.postingRequestStatus === POSTING_STATUS.REQUEST_CANCEL} */}
        {/* > */}
        {/*  申込内容確認 */}
        {/* </Button> */}

        <Button
          type="button"
          onClick={shopDownload}
          disabled={
            $.postingRequestStatus !== POSTING_STATUS.REQUEST_COMPLETE &&
            $.postingRequestStatus !== POSTING_STATUS.SECONDARY_CHECK
          }
        >
          掲載予約一括DL
        </Button>

        <Button
          type="button"
          onClick={() => {
            setIsDropModal(true);
          }}
          disabled={$.postingRequestStatus !== POSTING_STATUS.REQUEST_COMPLETE}
        >
          掲載予約一括UL
        </Button>

        <Button
          type="button"
          disabled={
            ($.summary.clientId !== 1 &&
              !$.contractShops.every(({ monitorRsvs }) =>
                monitorRsvs.every(
                  ({ checked, applicationFormStatus }) => (checked && applicationFormStatus === 1) || !checked
                )
              )) ||
            !$.contractShops.some((shop) => shop.monitorRsvs.some(({ checked }) => checked))
          }
          onClick={() =>
            mergeState({
              isStatusModal: true,
              monitorBaseIds: $.contractShops
                .flatMap((shop) =>
                  shop.monitorRsvs.map(({ checked, monitorBaseId }) => (checked ? monitorBaseId : undefined))
                )
                .filter((val): val is number => val !== undefined),
            })
          }
        >
          一括ステータス設定
        </Button>

        <Button
          type="button"
          disabled={$.matterHandoverSalesList.length === 0 || $.postingRequestStatus === POSTING_STATUS.REQUEST_CANCEL}
        >
          <Link to={`${Url.KEISAI.COMMENT}/${$.postingRequestId}`} target="_blank">
            <div style={{ color: 'white' }}>申送り事項確認</div>
          </Link>
        </Button>

        {when(
          $.postingRequestStatus === POSTING_STATUS.REQUEST_COMPLETE,
          <Button
            type="button"
            variant="danger"
            onClick={openRsvCancelConfirmModal}
            disabled={isSubmited || $.postingRequestStatus === POSTING_STATUS.REQUEST_CANCEL}
          >
            依頼取消
          </Button>
        )}

        {when(
          $.postingRequestStatus === POSTING_STATUS.REQUEST_COMPLETE,
          <Button
            type="button"
            variant="danger"
            onClick={() => onSecondcheck()}
            disabled={isSubmited || $.postingRequestStatus === POSTING_STATUS.REQUEST_CANCEL}
          >
            二次チェック依頼
          </Button>
        )}
        {when(
          $.postingRequestStatus === POSTING_STATUS.SECONDARY_CHECK,
          <>
            <Button
              type="button"
              variant="danger"
              onClick={() => onStatusChange(POSTING_STATUS.REQUEST_COMPLETE)}
              disabled={isSubmited || $.postingRequestStatus === POSTING_STATUS.REQUEST_CANCEL}
            >
              差し戻し
            </Button>
            <Button
              type="button"
              variant="danger"
              onClick={openRsvCompleteConfirmModal}
              disabled={isSubmited || $.postingRequestStatus === POSTING_STATUS.REQUEST_CANCEL}
            >
              掲載完了
            </Button>
          </>
        )}
      </div>

      <div className="mb-2">
        <Button
          type="button"
          size="sm"
          onClick={() => {
            mergeState({
              contractShops: $.contractShops?.map((s) => ({
                ...s,
                monitorRsvs: s.monitorRsvs.map((m) => ({ ...m, checked: true })),
              })),
            });
          }}
          disabled={$.postingRequestStatus === POSTING_STATUS.REQUEST_CANCEL}
        >
          全選択
        </Button>

        <Button
          type="button"
          variant="outline-secondary"
          size="sm"
          className="ms-2"
          onClick={() => {
            mergeState({
              contractShops: $.contractShops?.map((s) => ({
                ...s,
                monitorRsvs: s.monitorRsvs.map((m) => ({ ...m, checked: false })),
              })),
            });
          }}
          disabled={$.postingRequestStatus === POSTING_STATUS.REQUEST_CANCEL}
        >
          全解除
        </Button>
      </div>

      <PostingReservationShopTable
        postingRequestId={$.postingRequestId}
        clientId={$.summary.clientId}
        clientName={$.summary.clientName}
        contractShops={$.contractShops || []}
        applicationFormType={$.summary.applicationFormType || ''}
        setContractShops={useBindSet('contractShops')}
        setIsStatusModal={useBindSet('isStatusModal')}
        setIsBasicInfoModal={useBindSet('isBasicInfoModal')}
        setIsShopListModal={useBindSet('isShopListModal')}
        setMonitorBaseId={useBindSet('monitorBaseId')}
        setMonitorBaseIds={useBindSet('monitorBaseIds')}
        setIndex={useBindSet('index')}
        postingRequestStatus={$.postingRequestStatus}
      />
    </>
  );
};
