import { faAngleRight, faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilValue } from 'recoil';
import React, { useMemo, useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { useSafeContext } from '../../hooks/useSafeContext';
import sass from '../../scss/templates/CorrectionTemplate.module.scss';
import { CorrectionContext } from '../../store/correctionStore';
import { formatISODate, uuid, when } from '../../utils/functions';
import { VerticalTable } from '../molecules/VerticalTable';
import { ImageViewer } from '../organisms/Correction/ImageViewer';
import { Url } from '../../constants/Url';
import { CommonPanel } from '../organisms/Correction/Main/sections/CommonPanel';
import { DisplayPointType } from '../pages/Correction/constants';
import { OriginalLink } from '../atoms/OriginalLink';
import { CorrectionHistoryTable } from '../organisms/Table/CorrectionHistoryTable';
import { PageLinkList } from '../organisms/Correction/Escalation/parts/PageLinkList';
import { currentUserState } from '../../states/currentUser';
import { ROLE_NAME } from '../../RoleConstants';
import { MonitorRule } from '../organisms/Correction/Main/parts/MonitorRule';
import { CORRECTION_PAGE_SCROLL } from '../../Constants';

export const CorrectionTemplate: React.FC = ({ children }) => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);
  const applyInfo = useMemo(() => $.res_applyInfoDisplay?.applyInfo, [$.res_applyInfoDisplay?.applyInfo]);
  const [exif, setExif] = useState<string | undefined>();
  const correctHistories = useMemo(() => $.res_correctionHistoryDisplay, [$.res_correctionHistoryDisplay]);
  const currentUser = useRecoilValue(currentUserState);

  const getVerticalTableBody = () => {
    let result = {
      応募ID: [applyInfo?.applyId],
      '--------------------': ['---------------------------'],
      契約店舗ID: [applyInfo?.contractShopId],
      実店舗ID: [applyInfo?.shopId],
      ベースID: [applyInfo?.monitorBaseId],
      謝礼条件: [applyInfo?.rewardWord],
      APICLIENT: [applyInfo?.apiClientName],
    };

    if (currentUser?.role === ROLE_NAME.QC_CREW) {
      result = { ...result, ...{ 会員ID: [applyInfo?.customerId] } };
    } else {
      result = {
        ...result,
        ...{
          会員ID: [
            <OriginalLink
              url={`${Url.TENSAKU.CUSTOMER_INFO}/${applyInfo?.customerId}`}
              anotherTag
              title={applyInfo?.customerId.toString()}
            />,
          ],
        },
      };
    }

    result = {
      ...result,
      ...{
        モラル: [applyInfo?.moralName],
        完了数: [applyInfo?.completeCount],
        調査証明NG数: [applyInfo?.surveyProofNgCount],
        アンケートNG数: [applyInfo?.answerNgCount],
      },
    };

    if (applyInfo?.monitorBudget) {
      result = {
        ...result,
        ...{
          モニター予算: [applyInfo?.monitorBudget],
        },
      };
    }

    if (applyInfo?.shopBudget) {
      result = {
        ...result,
        ...{
          '店舗の平均予算(一人当たり)': [applyInfo?.shopBudget],
        },
      };
    }

    result = {
      ...result,
      ...{
        ホームページ: [
          <a href={applyInfo?.homePage} target="_blank" rel="noreferrer">
            {applyInfo?.homePage}
            <FontAwesomeIcon icon={faWindowRestore} className="ms-1" />
          </a>,
        ],
        クライアントID: [applyInfo?.clientId],
        クライアント名: [applyInfo?.client],
        営業担当者名: [applyInfo?.salesIncName],
        ファンくる店舗ページURL: [
          <OriginalLink
            url={applyInfo?.fancrewShopPageUrl || 'https://www.fancrew.co.jp'}
            anotherTag
            title={applyInfo?.fancrewShopPageUrl}
          />,
        ],
      },
    };
    return result;
  };

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    // スクロール位置を更新するための関数
    const handleScroll = () => {
      if (scrollContainer) {
        localStorage.setItem(CORRECTION_PAGE_SCROLL, String(scrollContainer.scrollTop));
      }
    };

    // ウインドウ(タブ)が閉じられたかを検知するための関数
    const handleBeforeUnloadEvent = () => {
      localStorage.removeItem(CORRECTION_PAGE_SCROLL);
    };

    // イベントリスナーを登録
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }
    window.addEventListener('beforeunload', handleBeforeUnloadEvent);

    // クリーンアップ: イベントリスナーを解除
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
      localStorage.removeItem(CORRECTION_PAGE_SCROLL);
    };
  }, []);

  useLayoutEffect(() => {
    // スクロール位置を復元する
    const savedCorrectionPageScroll = localStorage.getItem(CORRECTION_PAGE_SCROLL);
    if (savedCorrectionPageScroll && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo(0, Number(savedCorrectionPageScroll));
    }
  }, [$]);

  return (
    <>
      <Row className={sass.correction}>
        <Col className={sass.correction_formWrap}>
          <Row className={`${sass.correction_form} position-relative h-100`}>
            <Col className="px-0">
              <Row>
                <Col className={sass.correction_formCol} ref={scrollContainerRef}>
                  {children}
                </Col>
              </Row>
            </Col>
            <Col className={`${sass.correction_accordionWrap} position-absolute flex-grow-0`}>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="correction_accordion">
                <div
                  className={`${sass.correction_accordionArrow} bg-secondary d-flex justify-content-center align-items-center`}
                >
                  <FontAwesomeIcon icon={faAngleRight} className="text-white" />
                </div>
              </label>
            </Col>
          </Row>
        </Col>
        <input id="correction_accordion" className={sass.correction_accordion} type="checkbox" />
        <Col className={`${sass.correction_contents}`}>
          <Row className="h-100 flex-nowrap">
            <Col
              className={`${sass.correction_contentsCol} bg-white ${sass.correction_correction_contentsColFirst} border-end`}
            >
              <Accordion defaultActiveKey={[...Array(!$.isEnquete ? 3 : 4).keys()].map(String)} flush>
                {$.res_escalationInfoDisplay && $.res_escalationInfoDisplay.length > 0 ? (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className={sass.correction_accordionBtn}>エスカレ一覧</Accordion.Header>
                    <Accordion.Body className={sass.correction_accordionBody}>
                      <PageLinkList />
                    </Accordion.Body>
                  </Accordion.Item>
                ) : null}
                <Accordion.Item eventKey="1">
                  <Accordion.Header className={sass.correction_accordionBtn}>応募情報</Accordion.Header>
                  <Accordion.Body className={sass.correction_accordionBody}>
                    <div className={sass.correction_appinfo}>
                      <p>{applyInfo?.categoryName}</p>
                      <h5>店舗名</h5>
                      <p style={{ marginBottom: '0.5rem' }}>{applyInfo?.shopName}</p>
                      <p style={{ marginBottom: '0.5rem', fontSize: 'small' }}>{applyInfo?.freeEntryField}</p>
                      <h5>モニターベース名</h5>
                      <p>{applyInfo?.monitorBaseName}</p>
                      <p>{applyInfo?.businessCategoryName}</p>
                      <h5>住所</h5>
                      <p>{applyInfo?.address}</p>
                      <h5>当選日時</h5>
                      <p>{applyInfo?.winningAt && formatISODate(applyInfo.winningAt, 'yyyy年MM月dd日 HH時mm分')}</p>
                    </div>
                    <hr />
                    <VerticalTable
                      isPlainTable
                      className={`${sass.correction_table} w-100`}
                      body={getVerticalTableBody()}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                {!$.isEnquete ? (
                  <>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header className={sass.correction_accordionBtn}>Exif情報</Accordion.Header>
                      <Accordion.Body className={sass.correction_accordionBody}>{exif}</Accordion.Body>
                    </Accordion.Item>
                  </>
                ) : (
                  <>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header className={sass.correction_accordionBtn}>モニタールール</Accordion.Header>
                      <Accordion.Body className={sass.correction_accordionBody}>
                        {($.res_correctionCheckDisplay?.monitorRuleList ?? []).map((monitorRule) => (
                          <>
                            <h6 className="mt-2 mb-0">{monitorRule.typeName}</h6>
                            <MonitorRule monitorRule={monitorRule} isReadOnly />
                          </>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                )}
                <Accordion.Item eventKey="3">
                  <Accordion.Header className={sass.correction_accordionBtn}>添削履歴</Accordion.Header>
                  <Accordion.Body className={sass.correction_accordionBody}>
                    <CorrectionHistoryTable
                      correctionHistoryList={correctHistories}
                      correctionHistoryStyle={sass.correction_history}
                      correctionHistoryDateStyle={sass.correction_history_date}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col className={`${sass.correction_contentsCol} bg-white`}>
              <Accordion defaultActiveKey={[...Array(!$.isEnquete ? 1 : 2).keys()].map(String)} flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className={sass.correction_accordionBtn}>購入証明情報</Accordion.Header>
                  <Accordion.Body className={sass.correction_accordionBody}>
                    <ImageViewer onChangeExif={(exifInfo) => setExif(exifInfo)} />
                  </Accordion.Body>
                </Accordion.Item>
                {when(
                  !!$.isEnquete,
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className={sass.correction_accordionBtn}>Exif情報</Accordion.Header>
                    <Accordion.Body className={sass.correction_accordionBody}>{exif}</Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
