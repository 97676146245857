import { faLink, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Accordion, Button, Col, Form, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap';
import {
  MonitorRuleSetInfoMonitorRuleImageOutputResponse,
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoOutputResponse,
} from '../../api-client';
import { getImageSize } from '../../utils/s3';
import { Dropzone } from '../molecules/Dropzone';
import { ImageCard } from '../molecules/ImageCard';
import { ImageProp } from '../pages/MonitorRule/MonitorRuleSetModifyPage';
import { MonitorRuleSetCommonContents } from './MonitorRuleSetCommonContents';
import { uuid } from '../../utils/functions';

export interface Props {
  data: MonitorRuleSetInfoOutputResponse & ImageProp;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse & ImageProp>>;
  rowData: MonitorRuleSetInfoMonitorRuleOutputResponse;
  index: number;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonitorRuleId: React.Dispatch<React.SetStateAction<number>>;
}

// 「写真撮影（その他）」Contents
export const MonitorRuleSetPhotoShotOtherContents: React.VFC<Props> = ({
  data,
  rowData,
  setData,
  index,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
}) => {
  // 予約ラジオボタン有効無効化フラグ
  const [radioDisableFlg, setRadioDisableFlg] = useState<boolean>(true);
  // 「電話予約」かどうか
  const [reserveTypePhoneFlg, setreserveTypePhoneFlg] = useState<boolean>(false);
  // 「WEB予約」かどうか
  const [reserveTypeWEBFlg, setreserveTypeWEBFlg] = useState<boolean>(false);

  // 「指定メニュー・コース」の数
  const [itemTargetCount, setItemTargetCount] = useState<number | undefined>(0);
  const list: JSX.Element[] = [];
  const [listData, setListData] = useState<JSX.Element[]>(list);

  const sampleRange: any[] = [
    { id: 1, value: '全体が写るように撮影' },
    { id: 2, value: 'メニュー全体が写るように撮影' },
    { id: 99, value: 'その他（フリーテキスト）' },
  ];

  const sampleAngle: any[] = [
    { id: 1, angle: '真上' },
    { id: 2, angle: '真上または斜め上' },
    { id: 3, angle: '斜め上' },
    { id: 4, angle: '真横' },
    { id: 5, angle: '店舗外観（お店の名前がわかるように）が写る角度で撮影' },
    { id: 6, angle: 'メニュー全体が写るように撮影' },
    { id: 99, angle: 'その他（フリーテキスト）' },
  ];

  useEffect(() => {
    // 「指定メニュー・コース」の数
    const menuCount = data.monitorRuleList.find((_) => {
      return _.monitorRuleType === 23;
    })?.targetItemList?.length;
  }, [
    data.monitorRuleList.find((_) => {
      return _.monitorRuleType === 23;
    })?.targetItemList,
  ]);

  // 画像添付イベント
  const onSubDrop = async (acceptedFiles: any, ind: number, notItemLinked: boolean) => {
    // 1ファイルの場合の処理
    if (acceptedFiles.length !== 0 && acceptedFiles.length === 1) {
      const file = acceptedFiles[0];

      const { name, type: contentType } = file;
      const path = window.URL.createObjectURL(file);
      const format = name.split('.').pop();
      const size = await getImageSize(file).catch((error) => console.error('FileReader Error: ', error));
      const imageObj = {
        ...size,
        file,
        name,
        contentType,
        format,
        path,
        isDefault: false,
        monitorRuleListIdx: index,
        imageRuleListIdx: ind,
      };

      if (notItemLinked) {
        setData({
          ...data,
          monitorRuleList: data?.monitorRuleList.map((_, i) => {
            return i === index
              ? {
                  ..._,
                  notItemLinkedImageRuleList: _.notItemLinkedImageRuleList?.map((r, c) => {
                    return c === ind
                      ? {
                          ...r,
                          imageList: [
                            ...data.monitorRuleList[i].notItemLinkedImageRuleList[c].imageList,
                            {
                              imageId: data.monitorRuleList[i].notItemLinkedImageRuleList[c].imageList.length + 1,
                              imageURL: path,
                            },
                          ],
                        }
                      : { ...r };
                  }),
                }
              : { ..._ };
          }),
          notItemLinkedImageList:
            data.notItemLinkedImageList && data.notItemLinkedImageList.length > 0
              ? data.notItemLinkedImageList.concat(imageObj)
              : [imageObj],
        });
      } else {
        setData({
          ...data,
          monitorRuleList: data?.monitorRuleList.map((_, i) => {
            return i === index
              ? {
                  ..._,
                  imageRuleList: _.imageRuleList?.map((r, c) => {
                    return c === ind
                      ? {
                          ...r,
                          imageList: [
                            ...data.monitorRuleList[i].imageRuleList[c].imageList,
                            { imageId: data.monitorRuleList[i].imageRuleList[c].imageList.length + 1, imageURL: path },
                          ],
                        }
                      : { ...r };
                  }),
                }
              : { ..._ };
          }),
          imageList: data.imageList && data.imageList.length > 0 ? data.imageList.concat(imageObj) : [imageObj],
        });
      }
    }

    // // 複数ファイルの場合の処理
    // else {
    //   acceptedFiles.map((file: any, index: number) => {
    //     subImgData.push(createObject(acceptedFiles[index]));
    //     const newImgList = subImgData.concat(data2.subImgList);
    //     setData2({ ...data2, subImgList: newImgList });
    //   });
    // }
    // setDeleteReusult(undefined);
    // setKeepFlg(false);
  };

  // 画像削除イベント
  const onImageDelete = (
    newImgList: MonitorRuleSetInfoMonitorRuleImageOutputResponse[] | undefined,
    imageRuleListInd: number,
    imageListInd: number,
    isNotItemLinked: boolean
  ) => {
    // const newImgList : ImageListData[]  = Object.arguments([],data?.monitorRuleSetList?.find((d,i)=>{return i === index})?.imageRuleList![ind]?.imageList);
    if (newImgList) {
      if (isNotItemLinked) {
        setData({
          ...data,
          monitorRuleList: data?.monitorRuleList.map((_, i) => {
            return i === index
              ? {
                  ..._,
                  notItemLinkedImageRuleList: _.notItemLinkedImageRuleList?.map((r, inde) => {
                    return inde === imageRuleListInd
                      ? {
                          ...r,
                          imageList: newImgList.filter((image, ci) => {
                            return ci !== imageListInd;
                          }),
                        }
                      : { ...r };
                  }),
                }
              : { ..._ };
          }),
          notItemLinkedImageList: data.notItemLinkedImageList?.filter(
            (obj) =>
              obj.path !==
              data.monitorRuleList[index].notItemLinkedImageRuleList[imageRuleListInd].imageList[imageListInd].imageURL
          ),
        });
      } else {
        setData({
          ...data,
          monitorRuleList: data?.monitorRuleList.map((_, i) => {
            return i === index
              ? {
                  ..._,
                  imageRuleList: _.imageRuleList?.map((r, inde) => {
                    return inde === imageRuleListInd
                      ? {
                          ...r,
                          imageList: newImgList.filter((image, ci) => {
                            return ci !== imageListInd;
                          }),
                        }
                      : { ...r };
                  }),
                }
              : { ..._ };
          }),
          imageList: data.imageList?.filter(
            (obj) =>
              obj.path !== data.monitorRuleList[index].imageRuleList[imageRuleListInd].imageList[imageListInd].imageURL
          ),
        });
      }
    }
  };

  return (
    <>
      <div className="d-flex mb-4">
        <div style={{ width: '50%' }}>
          {data?.monitorRuleList[index].imageRuleList.map((imageRule, ind) => {
            const imageTarget2 = imageRule.imageTargetList?.[0];
            return (
              <div key={ind.toString()} className="d-flex">
                <Accordion className="mb-5" defaultActiveKey="0" style={{ border: '1px solid #dee2e6', width: '90%' }}>
                  <Row className="align-items-center">
                    <Col className="col-auto" style={{ marginLeft: '3rem', fontWeight: 'bold' }}>{`写真撮影${
                      ind + 1
                    }`}</Col>
                    <Col>
                      <Accordion.Header />
                    </Col>
                  </Row>
                  <Accordion.Body className="m-0 pe-1">
                    <Table style={{ borderRight: 'none', borderLeft: '1px solid #dee2e6' }}>
                      <tbody>
                        <tr key={`imageTarget${ind + 1}`} className="align-middle">
                          <th
                            style={{
                              borderRight: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              textAlign: 'center',
                            }}
                          >
                            撮影対象
                          </th>
                          <td style={{ borderRight: '1px solid #dee2e6', borderTop: '1px solid #dee2e6' }}>
                            <Form.Select
                              data-testid="imageTargetSelect"
                              value={imageTarget2 || ''}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          imageRuleList: _.imageRuleList?.map((r, c) => {
                                            return c === ind
                                              ? {
                                                  ...r,
                                                  imageTargetList:
                                                    r.imageTargetList && r.imageTargetList.length > 0
                                                      ? r.imageTargetList.map((imageTarget, targetInd) =>
                                                          targetInd === 0 ? e.target.value : imageTarget
                                                        )
                                                      : [e.target.value],
                                                }
                                              : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            >
                              {data.monitorRuleList
                                .find((_) => {
                                  return _.monitorRuleType === 23;
                                })
                                ?.targetItemList?.map((item, i) => (
                                  <option key={i.toString()} value={item.itemName}>
                                    {item.itemName}
                                  </option>
                                ))}
                            </Form.Select>
                          </td>
                        </tr>
                        <tr className="align-middle">
                          <th style={{ width: '20%', borderRight: '1px solid #dee2e6', textAlign: 'center' }}>
                            調査目的
                          </th>
                          <td
                            style={{
                              borderRight: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              borderBottom: '1px solid #dee2e6',
                            }}
                          >
                            <Form.Control
                              data-testid="objectiveText"
                              value={
                                rowData?.imageRuleList![ind]?.objective !== undefined
                                  ? rowData.imageRuleList[ind].objective
                                  : ''
                              }
                              type="text"
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          imageRuleList: _.imageRuleList?.map((r, inde) => {
                                            return inde === ind ? { ...r, objective: e.target.value } : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr className="align-middle">
                          <th style={{ width: '20%', borderRight: '1px solid #dee2e6', textAlign: 'center' }}>
                            タイミング
                          </th>
                          <td
                            style={{
                              borderRight: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              borderBottom: '1px solid #dee2e6',
                            }}
                          >
                            <Form.Control
                              data-testid="timingText"
                              value={rowData?.imageRuleList![ind]?.timing}
                              type="text"
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          imageRuleList: _.imageRuleList?.map((r, inde) => {
                                            return inde === ind
                                              ? { ...r, timing: e.target.value === '' ? undefined : e.target.value }
                                              : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr className="align-middle">
                          <th style={{ width: '20%', borderRight: '1px solid #dee2e6', textAlign: 'center' }}>範囲</th>
                          <td
                            style={{
                              borderRight: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              borderBottom: '1px solid #dee2e6',
                            }}
                          >
                            <Form.Select
                              data-testid="rangeSelect"
                              value={data?.monitorRuleList[index].imageRuleList![ind]?.range}
                              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          imageRuleList: _.imageRuleList?.map((r, inde) => {
                                            return inde === ind ? { ...r, range: Number(e.target.value) } : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            >
                              {sampleRange.map((item, i) => (
                                <option key={item.id} value={item.id}>
                                  {item.value}
                                </option>
                              ))}
                            </Form.Select>
                          </td>
                        </tr>
                        {/* 「範囲」フリーテキスト */}
                        {data?.monitorRuleList[index].imageRuleList![ind]?.range === 99 && (
                          <tr className="align-middle">
                            <td
                              colSpan={2}
                              style={{
                                borderRight: '1px solid #dee2e6',
                                borderTop: '1px solid #dee2e6',
                                borderBottom: '1px solid #dee2e6',
                              }}
                            >
                              <Form.Control
                                data-testid="rangeFreeText"
                                as="textarea"
                                rows={3}
                                value={data?.monitorRuleList[index].imageRuleList![ind]?.rangeFreeText}
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                      return i === index
                                        ? {
                                            ..._,
                                            imageRuleList: _.imageRuleList?.map((r, inde) => {
                                              return inde === ind
                                                ? {
                                                    ...r,
                                                    rangeFreeText: e.target.value === '' ? undefined : e.target.value,
                                                  }
                                                : { ...r };
                                            }),
                                          }
                                        : { ..._ };
                                    }),
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        )}
                        <tr className="align-middle">
                          <th style={{ width: '20%', borderRight: '1px solid #dee2e6', textAlign: 'center' }}>
                            撮影角度
                          </th>
                          <td
                            style={{
                              borderRight: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              borderBottom: '1px solid #dee2e6',
                            }}
                          >
                            <Form.Select
                              data-testid="angleSelect"
                              value={data?.monitorRuleList[index].imageRuleList![ind]?.angle}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          imageRuleList: _.imageRuleList?.map((r, inde) => {
                                            return inde === ind ? { ...r, angle: Number(e.target.value) } : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            >
                              {sampleAngle.map((item, i) => (
                                <option key={i.toString()} value={item.id}>
                                  {item.angle}
                                </option>
                              ))}
                            </Form.Select>
                          </td>
                        </tr>
                        {/* 「撮影角度」フリーテキスト */}
                        {data?.monitorRuleList[index].imageRuleList![ind]?.angle === 99 && (
                          <tr className="align-middle">
                            <td
                              colSpan={2}
                              style={{
                                borderRight: '1px solid #dee2e6',
                                borderTop: '1px solid #dee2e6',
                                borderBottom: '1px solid #dee2e6',
                              }}
                            >
                              <Form.Control
                                data-testid="angleFreeText"
                                as="textarea"
                                rows={3}
                                value={data?.monitorRuleList[index].imageRuleList![ind]?.angleFreeText}
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                      return i === index
                                        ? {
                                            ..._,
                                            imageRuleList: _.imageRuleList?.map((r, inde) => {
                                              return inde === ind
                                                ? {
                                                    ...r,
                                                    angleFreeText: e.target.value === '' ? undefined : e.target.value,
                                                  }
                                                : { ...r };
                                            }),
                                          }
                                        : { ..._ };
                                    }),
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        )}
                        <tr className="align-middle">
                          <th
                            style={{
                              width: '20%',
                              borderRight: '1px solid #dee2e6',
                              borderBottom: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              textAlign: 'center',
                            }}
                          >
                            その他注意
                          </th>
                          <td style={{ borderRight: '1px solid #dee2e6' }}>
                            <Form.Control
                              data-testid="imageOtherText"
                              as="textarea"
                              value={data?.monitorRuleList[index].imageRuleList![ind]?.other}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          imageRuleList: _.imageRuleList?.map((r, inde) => {
                                            return inde === ind
                                              ? { ...r, other: e.target.value === '' ? undefined : e.target.value }
                                              : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr className="align-middle">
                          <td
                            style={{ borderBottom: '1px solid #dee2e6', borderRight: '1px solid #dee2e6' }}
                            colSpan={2}
                          >
                            {/* 一つの写真撮影の中のうち画像の数だけ表示 */}
                            {data?.monitorRuleList
                              ?.find((_d, i) => {
                                return i === index;
                              })
                              ?.imageRuleList?.[ind]?.imageList?.map((ro, imageI) => {
                                return (
                                  <ImageCard
                                    url={ro.imageURL}
                                    onDelete={() => {
                                      onImageDelete(
                                        data?.monitorRuleList?.find((_d, i) => {
                                          return i === index;
                                        })?.imageRuleList?.[ind]?.imageList,
                                        ind,
                                        imageI,
                                        false
                                      );
                                    }}
                                  />
                                );
                              })}
                            <Dropzone
                              onDrop={(file) => {
                                onSubDrop(file, ind, false);
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion>
                {ind !== 0 && (
                  <div>
                    <Button
                      data-testid="imageRuleListDeleteButton"
                      variant="link"
                      className="text-secondary mt-2"
                      onClick={() => {
                        // const newList = data?.monitorRuleSetList?.find((d,i)=>{return i === index})?.imageRuleList?.filter((rw,i) =>{return i !== ind});
                        const copyImageList = Object.assign(
                          [],
                          data?.monitorRuleList
                            ?.find((d, i) => {
                              return d.monitorRuleType === 7;
                            })
                            ?.imageRuleList.filter((imageRow, imageI) => {
                              return imageI !== ind;
                            })
                        );
                        // 「撮影画像ルールリスト」の削除
                        setData({
                          ...data,
                          monitorRuleList: data?.monitorRuleList.map((_, i) => {
                            return _.monitorRuleType === 7 ? { ..._, imageRuleList: copyImageList } : { ..._ };
                          }),
                        });
                      }}
                      data-html
                    >
                      <FontAwesomeIcon data-testid="deleteButton" className="text-secondary" icon={faTimesCircle} />
                    </Button>
                  </div>
                )}
              </div>
            );
          })}
          {/* 非連動写真撮影 */}
          {data?.monitorRuleList[index].notItemLinkedImageRuleList.map((imageRule, ind) => {
            const imageTarget2 = imageRule.imageTargetList?.[0];
            return (
              <div key={ind.toString()} className="d-flex">
                <Accordion className="mb-5" defaultActiveKey="0" style={{ border: '1px solid #dee2e6', width: '90%' }}>
                  <Row className="align-items-center">
                    <Col
                      className="col-auto"
                      style={{ marginLeft: '3rem', fontWeight: 'bold', backgroundColor: 'lightblue' }}
                    >{`写真撮影${ind + 1 + data?.monitorRuleList[index].imageRuleList.length}`}</Col>
                    <Col>
                      <Accordion.Header />
                    </Col>
                  </Row>
                  <Accordion.Body className="m-0 pe-1">
                    <Table style={{ borderRight: 'none', borderLeft: '1px solid #dee2e6' }}>
                      <tbody>
                        <tr key={`notItemLinkedImageTarget${ind + 1}`} className="align-middle">
                          <th
                            style={{
                              borderRight: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              textAlign: 'center',
                            }}
                          >
                            撮影対象
                          </th>
                          <td style={{ borderRight: '1px solid #dee2e6', borderTop: '1px solid #dee2e6' }}>
                            <Form.Control
                              data-testid="imageTarget"
                              value={imageTarget2 || ''}
                              type="text"
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          notItemLinkedImageRuleList: _.notItemLinkedImageRuleList?.map((r, c) => {
                                            return c === ind
                                              ? {
                                                  ...r,
                                                  imageTargetList:
                                                    r.imageTargetList && r.imageTargetList.length > 0
                                                      ? r.imageTargetList.map((imageTarget, targetInd) =>
                                                          targetInd === 0 ? e.target.value : imageTarget
                                                        )
                                                      : [e.target.value],
                                                }
                                              : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr className="align-middle">
                          <th style={{ width: '20%', borderRight: '1px solid #dee2e6', textAlign: 'center' }}>
                            調査目的
                          </th>
                          <td
                            style={{
                              borderRight: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              borderBottom: '1px solid #dee2e6',
                            }}
                          >
                            <Form.Control
                              data-testid="objectiveText"
                              value={rowData?.notItemLinkedImageRuleList![ind]?.objective || ''}
                              type="text"
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          notItemLinkedImageRuleList: _.notItemLinkedImageRuleList?.map((r, inde) => {
                                            return inde === ind ? { ...r, objective: e.target.value } : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr className="align-middle">
                          <th style={{ width: '20%', borderRight: '1px solid #dee2e6', textAlign: 'center' }}>
                            タイミング
                          </th>
                          <td
                            style={{
                              borderRight: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              borderBottom: '1px solid #dee2e6',
                            }}
                          >
                            <Form.Control
                              data-testid="timingText"
                              value={rowData?.notItemLinkedImageRuleList![ind]?.timing || ''}
                              type="text"
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          notItemLinkedImageRuleList: _.notItemLinkedImageRuleList?.map((r, inde) => {
                                            return inde === ind
                                              ? { ...r, timing: e.target.value === '' ? undefined : e.target.value }
                                              : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr className="align-middle">
                          <th style={{ width: '20%', borderRight: '1px solid #dee2e6', textAlign: 'center' }}>範囲</th>
                          <td
                            style={{
                              borderRight: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              borderBottom: '1px solid #dee2e6',
                            }}
                          >
                            <Form.Select
                              data-testid="rangeSelect"
                              value={data?.monitorRuleList[index].notItemLinkedImageRuleList?.[ind]?.range || ''}
                              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          notItemLinkedImageRuleList: _.notItemLinkedImageRuleList?.map((r, inde) => {
                                            return inde === ind ? { ...r, range: Number(e.target.value) } : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            >
                              {sampleRange.map((item, i) => (
                                <option key={item.id} value={item.id}>
                                  {item.value}
                                </option>
                              ))}
                            </Form.Select>
                          </td>
                        </tr>
                        {/* 「範囲」フリーテキスト */}
                        {data?.monitorRuleList[index].notItemLinkedImageRuleList![ind]?.range === 99 && (
                          <tr className="align-middle">
                            <td
                              colSpan={2}
                              style={{
                                borderRight: '1px solid #dee2e6',
                                borderTop: '1px solid #dee2e6',
                                borderBottom: '1px solid #dee2e6',
                              }}
                            >
                              <Form.Control
                                data-testid="rangeFreeText"
                                as="textarea"
                                rows={3}
                                value={
                                  data?.monitorRuleList[index].notItemLinkedImageRuleList![ind]?.rangeFreeText || ''
                                }
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                      return i === index
                                        ? {
                                            ..._,
                                            notItemLinkedImageRuleList: _.notItemLinkedImageRuleList?.map((r, inde) => {
                                              return inde === ind
                                                ? {
                                                    ...r,
                                                    rangeFreeText: e.target.value === '' ? undefined : e.target.value,
                                                  }
                                                : { ...r };
                                            }),
                                          }
                                        : { ..._ };
                                    }),
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        )}
                        <tr className="align-middle">
                          <th style={{ width: '20%', borderRight: '1px solid #dee2e6', textAlign: 'center' }}>
                            撮影角度
                          </th>
                          <td
                            style={{
                              borderRight: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              borderBottom: '1px solid #dee2e6',
                            }}
                          >
                            <Form.Select
                              data-testid="angleSelect"
                              value={data?.monitorRuleList[index].notItemLinkedImageRuleList![ind]?.angle || ''}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          notItemLinkedImageRuleList: _.notItemLinkedImageRuleList?.map((r, inde) => {
                                            return inde === ind ? { ...r, angle: Number(e.target.value) } : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            >
                              {sampleAngle.map((item, i) => (
                                <option key={i.toString()} value={item.id}>
                                  {item.angle}
                                </option>
                              ))}
                            </Form.Select>
                          </td>
                        </tr>
                        {/* 「撮影角度」フリーテキスト */}
                        {data?.monitorRuleList[index].notItemLinkedImageRuleList![ind]?.angle === 99 && (
                          <tr className="align-middle">
                            <td
                              colSpan={2}
                              style={{
                                borderRight: '1px solid #dee2e6',
                                borderTop: '1px solid #dee2e6',
                                borderBottom: '1px solid #dee2e6',
                              }}
                            >
                              <Form.Control
                                data-testid="angleFreeText"
                                as="textarea"
                                rows={3}
                                value={
                                  data?.monitorRuleList[index].notItemLinkedImageRuleList![ind]?.angleFreeText || ''
                                }
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                      return i === index
                                        ? {
                                            ..._,
                                            notItemLinkedImageRuleList: _.notItemLinkedImageRuleList?.map((r, inde) => {
                                              return inde === ind
                                                ? {
                                                    ...r,
                                                    angleFreeText: e.target.value === '' ? undefined : e.target.value,
                                                  }
                                                : { ...r };
                                            }),
                                          }
                                        : { ..._ };
                                    }),
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        )}
                        <tr className="align-middle">
                          <th
                            style={{
                              width: '20%',
                              borderRight: '1px solid #dee2e6',
                              borderBottom: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              textAlign: 'center',
                            }}
                          >
                            その他注意
                          </th>
                          <td style={{ borderRight: '1px solid #dee2e6' }}>
                            <Form.Control
                              data-testid="imageOtherText"
                              as="textarea"
                              value={data?.monitorRuleList[index].notItemLinkedImageRuleList![ind]?.other || ''}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          notItemLinkedImageRuleList: _.notItemLinkedImageRuleList?.map((r, inde) => {
                                            return inde === ind
                                              ? { ...r, other: e.target.value === '' ? undefined : e.target.value }
                                              : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr className="align-middle">
                          <td
                            style={{ borderBottom: '1px solid #dee2e6', borderRight: '1px solid #dee2e6' }}
                            colSpan={2}
                          >
                            {/* 一つの写真撮影の中のうち画像の数だけ表示 */}
                            {data?.monitorRuleList
                              ?.find((_d, i) => {
                                return i === index;
                              })
                              ?.notItemLinkedImageRuleList?.[ind]?.imageList?.map((ro, imageI) => {
                                return (
                                  <ImageCard
                                    key={uuid()}
                                    url={ro.imageURL}
                                    onDelete={() => {
                                      onImageDelete(
                                        data?.monitorRuleList?.find((_d, i) => {
                                          return i === index;
                                        })?.notItemLinkedImageRuleList?.[ind]?.imageList,
                                        ind,
                                        imageI,
                                        true
                                      );
                                    }}
                                  />
                                );
                              })}
                            <Dropzone
                              onDrop={(file) => {
                                onSubDrop(file, ind, true);
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion>
                {/* 写真撮影の削除。非連動写真撮影は全て削除可能にする */}
                <div>
                  <Button
                    data-testid="imageRuleListDeleteButton"
                    variant="link"
                    className="text-secondary mt-2"
                    onClick={() => {
                      const copyImageList = Object.assign(
                        [],
                        data?.monitorRuleList
                          ?.find((d, i) => {
                            return d.monitorRuleType === 7;
                          })
                          ?.notItemLinkedImageRuleList.filter((imageRow, imageI) => {
                            return imageI !== ind;
                          })
                      );
                      setData({
                        ...data,
                        monitorRuleList: data?.monitorRuleList.map((_, i) => {
                          return _.monitorRuleType === 7
                            ? { ..._, notItemLinkedImageRuleList: copyImageList }
                            : { ..._ };
                        }),
                      });
                    }}
                    data-html
                  >
                    <FontAwesomeIcon data-testid="deleteButton" className="text-secondary" icon={faTimesCircle} />
                  </Button>
                </div>
              </div>
            );
          })}
          <div className="d-flex justify-content mb-2">
            <Button
              data-testid="imageTargetAddButton"
              variant="link"
              className="text-secondary"
              onClick={() => {
                setData({
                  ...data,
                  monitorRuleList: data?.monitorRuleList.map((row) => {
                    if (row.monitorRuleType === 7) {
                      // 写真撮影
                      return {
                        ...row,
                        notItemLinkedImageRuleList: [
                          ...row.notItemLinkedImageRuleList,
                          {
                            angle: 1,
                            angleFreeText: undefined,
                            objective: '',
                            other: undefined,
                            range: 1,
                            rangeFreeText: undefined,
                            timing: undefined,
                            imageTargetList: [],
                            imageList: [],
                            isNotItemLinked: true,
                          },
                        ],
                      };
                    }
                    return { ...row };
                  }),
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
              写真撮影追加
            </Button>
          </div>
        </div>
      </div>
      <MonitorRuleSetCommonContents
        data={data}
        rowData={rowData}
        setData={setData}
        index={index}
        contentsFlg
        setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
      />
    </>
  );
};
