import { faLink, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap';
import {
  MonitorRuleSetInfoMonitorRuleImageRuleOutputResponse,
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse,
  MonitorRuleSetInfoOutputResponse,
} from '../../api-client';
import { MonitorRuleSetCommonContents } from './MonitorRuleSetCommonContents';
import { ImageProp } from '../pages/MonitorRule/MonitorRuleSetModifyPage';

export interface Props {
  index: number;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  data: MonitorRuleSetInfoOutputResponse & ImageProp;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse & ImageProp>>;
  rowData: MonitorRuleSetInfoMonitorRuleOutputResponse;
  setSelectedMonitorRuleId: React.Dispatch<React.SetStateAction<number>>;
  setSpecifyItemUpdateFlg: React.Dispatch<React.SetStateAction<boolean>>;
}

// 「指定メニュー・コース」Contents
export const MonitorRuleSetSpecifyMenuCourseContents: React.VFC<Props> = ({
  data,
  rowData,
  setData,
  index,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
  setSpecifyItemUpdateFlg,
}) => {
  const [itemList, setItemList] = useState<MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse[]>([]);
  const [requireMessage, setRequireMessage] = useState<string>('');

  // 写真撮影確認フラグ
  const [checkPhotoShotFlg, setCheckPhotoShotFlg] = useState<boolean>(false);

  useEffect(() => {
    setItemList(
      Object.assign(
        [],
        data?.monitorRuleList?.find((_) => {
          return _.monitorRuleType === 23;
        })?.targetItemList
      )
    );
  }, [
    data?.monitorRuleList?.find((_) => {
      return _.monitorRuleType === 23;
    })?.targetItemList,
  ]);

  // 指定入力チェック
  const checkSpecifiedItems = (items: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse[]): boolean => {
    if (!items.length) return false;
    if (items.some((item) => !item.itemName && !item.supplement)) {
      return false;
    }
    return true;
  };

  // 商品
  const setItemName = (itemName: string | undefined) => {
    if (!itemName) return '';
    if (itemName === '全メニュー') {
      return `${itemName}の中からいずれか1つ\n`;
    }
    return `${itemName}\n`;
  };

  const setBeautyText = (
    items: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse[]
  ): { reflection: string; supplement: string } => {
    let reflection = '';
    let supplement = '';
    let supplementItemCount = '';

    // 指定入力チェック
    if (!checkSpecifiedItems(items)) {
      setRequireMessage('商品または補足欄を入力してください。');
      return { reflection, supplement };
    }

    // 指定商品の数で分岐
    if (items.length === 1) {
      reflection = '下記(1)を含んだ施術を受けてください。\n';
      reflection += `1.${items[0].supplement ? items[0].supplement : setItemName(items[0].itemName)}`;
      supplementItemCount = '(1)';
    } else {
      reflection = `下記（1～${items.length}）を含んだ施術を受けてください。\n`;
      supplementItemCount = `（1～${items.length}）`;

      items.forEach((item, i) => {
        reflection += `${i + 1}.`;
        reflection += item.supplement ? item.supplement : setItemName(item.itemName);
      });
    }

    // 補足文を組み立て
    supplement = `※${supplementItemCount}以外のメニュー追加は自由です。\n※${supplementItemCount}以外のメニュー追加は自由ですが、謝礼の対象にはなりません。\n※${supplementItemCount}以外のメニュー追加は禁止です。確認された場合は謝礼お支払いになりません。\n`;

    return { reflection, supplement };
  };

  return (
    <>
      {requireMessage && <div style={{ color: 'red' }}>{requireMessage}</div>}
      {checkPhotoShotFlg && (
        <div style={{ color: 'blue' }}>
          指定メニュー・コースを変えた場合は、写真撮影欄も変更が必要ないかご確認ください。
        </div>
      )}
      <div className="d-flex ">
        <Table className="table-borderless" width="100%">
          <thead>
            <tr>
              <th> </th>
              <th>商品</th>
              <th>補足</th>
            </tr>
          </thead>
          <tbody>
            {itemList?.map((_: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse, ind: number) => {
              return (
                <tr key={ind.toString()} className="align-middle">
                  <td>{ind + 1}</td>
                  <td>
                    <Form.Control
                      data-testid="itemNameText"
                      value={_.itemName}
                      type="text"
                      onChange={(e) => {
                        const newItemList: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse[] = itemList.map(
                          (row: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse, i) => {
                            return i === ind ? { ...row, itemName: e.target.value } : { ...row };
                          }
                        );
                        setItemList(newItemList);
                        setSpecifyItemUpdateFlg(true);
                      }}
                    />
                  </td>
                  <td>
                    <Form.Control
                      data-testid="itemSupplementText"
                      as="textarea"
                      rows={3}
                      value={_.supplement}
                      type="text"
                      onChange={(e) => {
                        const newItemList: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse[] = itemList.map(
                          (row: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse, i) => {
                            return i === ind ? { ...row, supplement: e.target.value } : { ...row };
                          }
                        );
                        setItemList(newItemList);
                        setSpecifyItemUpdateFlg(true);
                      }}
                    />
                  </td>
                  <td>
                    <Button
                      data-testid={`itemDeleteButton${ind + 1}`}
                      className="text-secondary bg-transparent border-0"
                      onClick={() => {
                        setItemList(
                          itemList.filter((row, i) => {
                            return i !== ind;
                          })
                        );
                        setSpecifyItemUpdateFlg(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-end mb-4">
        <Button
          data-testid="itemAddButton"
          variant="link"
          className="text-secondary p-0"
          onClick={() => {
            const newTargetList = Object.assign([], itemList);
            newTargetList.push({
              itemName: undefined,
              orderQuantity: undefined,
              supplement: undefined,
              imageFlg: undefined,
              imageQuantity: undefined,
              excludedItem: undefined,
            });

            setItemList(newTargetList);
            setSpecifyItemUpdateFlg(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
          <span style={{ fontSize: '1rem' }}>追加</span>
        </Button>
        <Button
          data-testid="menuReflectButton"
          className="ms-3"
          variant="secondary"
          onClick={() => {
            setRequireMessage('');
            let reflectionText = '';
            let supplementText = '';
            // モニタールールセット種別が美容の場合
            if (data.monitorRuleSetType === 6) {
              const beautyText = setBeautyText(itemList);
              reflectionText = beautyText.reflection;
              supplementText = beautyText.supplement;
            }

            // 必須チェックでエラーの場合、反映処理は実行しないようにする。
            if (!reflectionText && !supplementText) {
              return;
            }

            if (itemList !== undefined && data.monitorRuleSetType !== 6) {
              // 指定商品の数で分岐
              if (itemList.length === 1) {
                reflectionText = '下記(1)のメニュー・コースを受けてください。\n';
              } else {
                reflectionText = `下記（1～${itemList.length}）のメニュー・コースを受けてください。\n`;
              }

              for (let i = 0; i < itemList.length; i += 1) {
                reflectionText = `${reflectionText}${i + 1}.`;

                // 補足文の入力があるかどうか
                if (itemList[i].supplement !== undefined && itemList[i].supplement !== '') {
                  reflectionText = `${reflectionText}下記のいずれかのメニュー・コース\n${itemList[i].supplement}\n`;
                }
                // 補足文の入力がない場合
                else {
                  reflectionText = `${reflectionText}${itemList[i].itemName}\n`;
                }
              }
            }

            // 指定メニュー・コース・写真撮影の追加・更新--------------------------------------------------------
            let copyImageRuleList: MonitorRuleSetInfoMonitorRuleImageRuleOutputResponse[] = [];
            copyImageRuleList = [
              ...(data.monitorRuleList.find((r, i) => r.monitorRuleType === 7)?.imageRuleList || []),
            ];

            const firstItem: any[] | undefined = itemList.map((_) => {
              return _.itemName;
            });

            copyImageRuleList = copyImageRuleList.filter((copyImage) => {
              // firstItem の各要素について、copyImage の条件に合致するものがあるかチェックする
              return firstItem.some((item) => copyImage.imageTargetList?.includes(item.itemName));
            });

            const updatedImageList: NonNullable<ImageProp['imageList']> = (data?.imageList ?? [])
              .map((a) => {
                const matchedIndex = copyImageRuleList.findIndex((item) =>
                  item.imageList?.some((image) => image.imageURL === a.path)
                );

                if (matchedIndex !== -1) {
                  return {
                    ...a,
                    imageRuleListIdx: matchedIndex, // copyImageRuleList 内のインデックスを設定
                  };
                }
                return null; // 条件に合わない場合は null
              })
              .filter((item): item is NonNullable<ImageProp['imageList']>[number] => item !== null);

            const addItemCount = itemList.length - copyImageRuleList.length;
            for (let ind = 0; ind < addItemCount; ind += 1) {
              if (firstItem) {
                copyImageRuleList.push({
                  angle: undefined,
                  angleFreeText: undefined,
                  objective: '',
                  other: undefined,
                  range: undefined,
                  rangeFreeText: undefined,
                  timing: undefined,
                  imageTargetList: firstItem,
                  imageList: [],
                  isNotItemLinked: false,
                });
              }
            }

            setData({
              ...data,
              imageList: updatedImageList,
              monitorRuleList: data?.monitorRuleList.map((row) => {
                if (row.monitorRuleType === 23) {
                  return {
                    ...row,
                    beforeApplyContent: reflectionText,
                    afterApplyContent: reflectionText,
                    supplementList: row.supplementList.map((item, i) => {
                      return i === 0 ? { ...item, supplement: supplementText } : { ...item };
                    }),
                    targetItemList: itemList,
                  };
                }
                if (row.monitorRuleType === 7) {
                  return { ...row, imageRuleList: itemList.length !== 0 ? copyImageRuleList : [] };
                }
                return { ...row };
              }),
            });
            // 写真撮影を確認させる。
            setCheckPhotoShotFlg(true);
            // 反映ボタンを押下したら指定商品更新フラグを戻す。
            setSpecifyItemUpdateFlg(false);
          }}
        >
          反映
        </Button>
      </div>
      <MonitorRuleSetCommonContents
        data={data}
        rowData={rowData}
        setData={setData}
        index={index}
        contentsFlg
        setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
      />
    </>
  );
};
