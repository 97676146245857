import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import StateManagedSelect from 'react-select/dist/declarations/src/stateManager';
import { Button } from '../../../atoms/Button';
import { useLargeState } from '../../../../hooks/useLargeState';
import { createTestId, formatISODate, nameof, uuid, when } from '../../../../utils/functions';
import { useSafeContext } from '../../../../hooks/useSafeContext';
import { PointHistoryContext } from '../../../../store/pointPageStore';
import type { State as ParentState } from '../PointHistoryPage';
import { DATETIME_DISPLAY_FORMAT } from '../../../../Constants';
import { CustomerInfoHeader } from '../../Customer/CustomerInfoHeader';
import { PointDecreaseModal } from '../../../organisms/Modal/PointDecreaseModal';

interface Props {
  onNew: () => void;
  onSearch: () => void;
  onInfo: (index: number) => void;
}

type FormsState = ParentState['forms'];

export const PointHistoryListPage: React.FC<Props> = ({ onNew, onSearch, onInfo }) => {
  const testid = createTestId(PointHistoryListPage);

  const { state: $, mergeState } = useSafeContext(PointHistoryContext);
  const { state: f, onChangeSet } = useLargeState<ParentState['forms']>({
    searchType: 'createAt',
  });
  const [isDecreaseModal, setIsDecreaseModal] = useState<boolean>(false);

  useEffect(() => mergeState({ forms: f }), [mergeState, f]);

  return (
    <>
      <PointDecreaseModal
        customerId={$.customerId}
        amount={Number($.historyData?.totalPoint)}
        isShow={isDecreaseModal}
        setIsShow={setIsDecreaseModal}
      />
      <CustomerInfoHeader activeKey="pointHistory" customerId={$.customerId} />
      <Form>
        <Form.Group data-testid={testid(nameof<FormsState>('applyId'))}>
          <Form.Label>応募ID</Form.Label>
          <Form.Control type="number" autoComplete="off" value={f.applyId ?? ''} onChange={onChangeSet('applyId')} />
        </Form.Group>
        <Form.Group data-testid={testid(nameof<FormsState>('pointType'))}>
          <Form.Label>ポイントタイプ</Form.Label>
          <Form.Select value={f.pointType} onChange={onChangeSet('pointType')}>
            <option hidden defaultChecked>
              選択してください
            </option>
            {$.pointTypes.map(({ code, name }) => (
              <option key={uuid()} value={code}>
                {name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <small className="text-danger font-weight-light">
          ※【注意】ポイント失効を救済する際は、「失効」と「失効（旧基盤から移行する際の持ち越し分）」でそれぞれ絞り込みをして下さい！
        </small>
        <Form.Group data-testid={testid('searchType')}>
          <Form.Label>日付検索タイプ</Form.Label>
          <Form.Select value={f.searchType} onChange={onChangeSet('searchType')}>
            {(
              [
                ['登録年月日', 'createAt'],
                ['有効期限', 'expirationDate'],
              ] as [string, FormsState['searchType']][]
            ).map(([label, value]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {when(
          f.searchType === 'createAt',
          <Row>
            <Col>
              <Form.Group data-testid={testid(nameof<FormsState>('createAtStart'))}>
                <Form.Control
                  type="date"
                  autoComplete="off"
                  value={f.createAtStart ?? ''}
                  onChange={onChangeSet('createAtStart')}
                />
              </Form.Group>
            </Col>
            <Col xs="1">～</Col>
            <Col>
              <Form.Group data-testid={testid(nameof<FormsState>('createAtEnd'))}>
                <Form.Control
                  type="date"
                  autoComplete="off"
                  value={f.createAtEnd ?? ''}
                  onChange={onChangeSet('createAtEnd')}
                />
              </Form.Group>
            </Col>
          </Row>
        )}
        {when(
          f.searchType === 'expirationDate',
          <Row>
            <Col>
              <Form.Group data-testid={testid(nameof<FormsState>('expirationDateStart'))}>
                <Form.Control
                  type="date"
                  autoComplete="off"
                  value={f.expirationDateStart ?? ''}
                  onChange={onChangeSet('expirationDateStart')}
                />
              </Form.Group>
            </Col>
            <Col xs="1">～</Col>
            <Col>
              <Form.Group data-testid={testid(nameof<FormsState>('expirationDateEnd'))}>
                <Form.Control
                  type="date"
                  autoComplete="off"
                  value={f.expirationDateEnd ?? ''}
                  onChange={onChangeSet('expirationDateEnd')}
                />
              </Form.Group>
            </Col>
          </Row>
        )}
      </Form>
      <Button data-testid={testid('search-button')} onClick={onSearch}>
        絞り込み
      </Button>
      <ul>
        <li data-testid={testid('totalPoint')}>所持ポイント: {$.historyData?.totalPoint ?? '--'}</li>
        <li data-testid={testid('filteringTotalPoint')}>
          絞り込み後合計ポイント: {$.historyData?.filteringTotalPoint ?? '--'}
        </li>
      </ul>
      <Button data-testid={testid('new-button')} onClick={onNew}>
        ポイント新規追加
      </Button>
      <Button style={{ marginLeft: '10px' }} onClick={() => setIsDecreaseModal(true)} variant="warning">
        ポイント減算
      </Button>
      <Table data-testid={testid('table')}>
        <thead>
          <tr className="align-middle">
            <th>#</th>
            <th>ポイントタイプ</th>
            <th>応募ID</th>
            <th>付与実行社員メンバー</th>
            <th>店舗名モニター名</th>
            <th>ポイント</th>
            <th>ポイント交換先</th>
            <th>内部コメント</th>
            <th>登録日時</th>
            <th>使用期限</th>
            <th>詳細</th>
          </tr>
        </thead>
        <tbody>
          {($.historyData?.pointHistoryList ?? []).map(
            (
              {
                pointType,
                applyId,
                incAccountName,
                monitorShopName,
                points,
                exchangePointsService,
                internalComment,
                createAt,
                expirationDate,
                textTag,
              },
              i
            ) => {
              return (
                <tr key={uuid()} className="align-middle">
                  <td>{i + 1}</td>
                  <td>{$.pointTypes.find((x) => x.code === pointType)?.name}</td>
                  <td>{applyId}</td>
                  <td>{incAccountName}</td>
                  <td>
                    {monitorShopName}
                    <br />
                    <p style={{ display: 'inline', color: 'gray' }}>{textTag}</p>
                  </td>
                  <PointView points={points} />
                  <td>{exchangePointsService}</td>
                  <td>{internalComment}</td>
                  <td>{createAt && formatISODate(createAt, DATETIME_DISPLAY_FORMAT)}</td>
                  <td>{expirationDate && formatISODate(expirationDate, DATETIME_DISPLAY_FORMAT)}</td>
                  <td>
                    <Button data-testid={testid('info-button', i)} onClick={() => onInfo(i)}>
                      詳細
                    </Button>
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </Table>
    </>
  );
};

const PointView: React.FC<{ points: number }> = ({ points }) => {
  if (points < 0) return <td style={{ color: 'red' }}>{points}</td>;
  return <td>{points}</td>;
};
