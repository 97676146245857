/* eslint-disable import/no-cycle */
import React from 'react';
import { Card, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ApplyInfoOutputResponse } from '../../../../api-client';
import { Url } from '../../../../constants/Url';
import { createTestId, formatISODate } from '../../../../utils/functions';
import { Button } from '../../../atoms/Button';
import { VerticalTable } from '../../../molecules/VerticalTable';
import sass from '../../../../scss/organisms/ApplyMonitorInfoCard.module.scss';

export interface Props {
  applyInfo: ApplyInfoOutputResponse;
}

const ApplyMonitorInfoCard: React.FC<Props> = ({ applyInfo }) => {
  const testid = createTestId(ApplyMonitorInfoCard);
  const history = useHistory();

  return (
    <Card className="mb-4" data-testid={testid('monitorInfoCard')}>
      <Card.Body>
        <Form.Group className="mb-4">
          <div className="fs-5 p-1 fw-bold">応募モニター情報</div>
          <div className="bg-light p-3 mb-2">
            店舗
            <div className="fw-bold">
              {`${applyInfo?.applyMonitorInfo.shopId} ${applyInfo?.applyMonitorInfo.shopName}`}
            </div>
          </div>
          <div className="bg-light p-3 mb-2">
            店舗モニター
            <div className="fw-bold">{`${applyInfo?.applyMonitorInfo.monitorId} ${applyInfo?.applyMonitorInfo.monitorBaseName}`}</div>
            <div className="text-secondary">{`開始月${
              applyInfo?.applyMonitorInfo.monitorStartAt &&
              formatISODate(applyInfo?.applyMonitorInfo.monitorStartAt, 'yyyy/MM')
            }`}</div>
            <div className="float-right">
              <Button
                onClick={() => {
                  history.push(Url.TENSAKU.SHOP_REPLACE);
                }}
              >
                編集
              </Button>
            </div>
          </div>
          <div className="bg-light p-3">謝礼</div>
          <div className="d-flex bg-light p-3 gap-2">
            <VerticalTable
              isPlainTable
              className={sass.table}
              body={applyInfo.applyMonitorInfo.rateOutput.reduce((p, c) => ({ ...p, [c.itemName]: [c.value] }), {})}
            />
          </div>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default ApplyMonitorInfoCard;
